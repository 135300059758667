angular.module("app")
    .controller("travelPolicyAddController", ["$scope", "$log", "$state", "Notification", "travelPolicyService", function ($scope, $log, $state, Notification, travelPolicyService) {
        var vm = this;
        vm.loading = false;
        vm.loadingForSave = false;
        vm.dayOfWeek = ['Any', 'Mon-Fri', 'Sat-Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        vm.tripReasonMode = ['OPTIONAL', 'REQUIRED', 'NOT_REQUIRED'];
        vm.paymentMethodMode = ['DEFAULT', 'REQUIRE_SELECTION'];
        vm.complianceMode = ['AUDIT', 'WARN', 'DISALLOW'];
        vm.timePicker = {
            hourStep: 1,
            minStep: 15,
            isMeridian: false
        };
        vm.policyDetails = undefined;
        var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
        var weekends = ['Sat', 'Sun'];

        (function init() {
            bindLoaderStateEvents();
            var detail = getDefaultPolicyDetails(vm.dayOfWeek[1]);
            vm.policyDetails = [detail];
        })();

        vm.addTravelPolicy = function (travelPolicy) {
            vm.loadingForSave = true;
            buildPolicyDetails(travelPolicy);
            return travelPolicyService.create(travelPolicy)
                .then(function (results) {
                    $state.go("travel-policies");
                })
                .catch(function (resp) {
                    if (resp.data.messages) {
                        _.each(resp.data.messages, function (message) {
                            Notification.error({message: message, delay: 5000});
                        });
                    } else {
                        Notification.error({
                            message: 'There was a problem adding this policy, please refresh and try again',
                            delay: 5000
                        });
                    }
                }).finally(function () {
                    vm.loadingForSave = false;
                });
        };

        vm.addPolicyDetail = function ($event) {
            var detail = getDefaultPolicyDetails();
            vm.policyDetails.push(detail);
            $event.preventDefault();
        };

        vm.validatePolicyName = function (policyName, nameInputfield) {
            travelPolicyService.validatePolicyName(policyName)
                .then(function (data) {
                    nameInputfield.$setValidity("policyNameNotUnique", data);
                });
        };

        vm.changeStartTime = function (policyDetail) {
            policyDetail.startTime = dateToLocalString(policyDetail.startDateTime);
        };

        vm.changeEndTime = function (policyDetail) {
            policyDetail.endTime = dateToLocalString(policyDetail.endDateTime);
        };

        function dateToLocalString(fullDate) {
            var timeString = fullDate.toLocaleTimeString([], {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit'
            });
            return timeString;
        }

        function buildPolicyDetails(travelPolicy) {
            travelPolicy.travelPolicyDetails = [];
            _.forEach(vm.policyDetails, function (policyDetail) {
                if (policyDetail.dayOfWeek == vm.dayOfWeek[1]) {
                    breakDownByDay(policyDetail, travelPolicy.travelPolicyDetails, weekdays);
                } else if (policyDetail.dayOfWeek == vm.dayOfWeek[2]) {
                    breakDownByDay(policyDetail, travelPolicy.travelPolicyDetails, weekends);
                } else {
                    travelPolicy.travelPolicyDetails.push(policyDetail);
                }
                $log.debug('policy %o', travelPolicy);
            });
        }

        function breakDownByDay(policyDetail, policyDetails, days) {
            _.forEach(days, function (day) {
                var detailByDay = {
                    dayOfWeek: day,
                    startTime: policyDetail.startTime,
                    endTime: policyDetail.endTime,
                    limitAmount: policyDetail.limitAmount,
                    tripReasonMode: policyDetail.tripReasonMode
                };
                policyDetails.push(detailByDay);
            }.bind(this));
        }

        function getDefaultPolicyDetails(dayOfWeek) {
            var startTime = new Date();
            startTime.setHours(9);
            startTime.setMinutes(0);

            var endTime = new Date();
            endTime.setHours(17);
            endTime.setMinutes(0);
            var defaultDetail = {
                dayOfWeek: dayOfWeek,
                startDateTime: startTime,
                startTime: dateToLocalString(startTime),
                endDateTime: endTime,
                endTime: dateToLocalString(endTime),
                limitAmount: undefined,
                tripReasonMode: 'NOT_REQUIRED'
            };
            return defaultDetail;
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });
        }

    }]);
